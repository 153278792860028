var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.spinShow}},[_c('div',{directives:[{name:"malihu-scrollbar",rawName:"v-malihu-scrollbar"}],staticStyle:{"overflow-y":"auto"},style:({'max-height': _vm.bodyHeight + 'px'})},[_c('a-form',{staticClass:"form-container-body-form",attrs:{"form":_vm.form}},[_c('a-row',[_c('a-form-item',_vm._b({attrs:{"label":"备注名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.chooseData.systemModule),expression:"!chooseData.systemModule"},{name:"decorator",rawName:"v-decorator",value:(['title',
                                  {rules:[
                                    {type:'string',required:true,message:'名称不能为空'}
                                    ],trigger:'change',initialValue:'新建标备注'}
                                  ]),expression:"['title',\n                                  {rules:[\n                                    {type:'string',required:true,message:'名称不能为空'}\n                                    ],trigger:'change',initialValue:'新建标备注'}\n                                  ]"}],attrs:{"placeholder":"请输入名称"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.chooseData.systemModule),expression:"chooseData.systemModule"}]},[_vm._v(_vm._s(_vm.form.getFieldValue('title')))])],1)],1),_c('a-row',[_c('a-form-item',_vm._b({key:"color",attrs:{"label":"字体颜色"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['color',
                            {rules:[
                              {type:'string',required:true,message:'请输入字体颜色'}
                              ],trigger:'change',initialValue:'#111413'}
                            ]),expression:"['color',\n                            {rules:[\n                              {type:'string',required:true,message:'请输入字体颜色'}\n                              ],trigger:'change',initialValue:'#111413'}\n                            ]"}],attrs:{"placeholder":"请输入字体颜色"}})],1)],1),_c('a-row',[_c('a-form-item',_vm._b({key:"fontSize",attrs:{"label":"字体大小(px)"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fontSize',
                                        {rules:[
                                          {required:true,message:'请输入字体大小'}
                                          ],trigger:'change',initialValue:14}
                                        ]),expression:"['fontSize',\n                                        {rules:[\n                                          {required:true,message:'请输入字体大小'}\n                                          ],trigger:'change',initialValue:14}\n                                        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入字体大小"}})],1)],1),_c('a-row',[_c('a-form-item',_vm._b({key:"backgroundColor",attrs:{"label":"背景颜色"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['backgroundColor',
                                               {rules:[
                                                 {required:true,message:'请输入背景颜色'}
                                                 ],trigger:'change',initialValue:'transparent'}
                                               ]),expression:"['backgroundColor',\n                                               {rules:[\n                                                 {required:true,message:'请输入背景颜色'}\n                                                 ],trigger:'change',initialValue:'transparent'}\n                                               ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入背景颜色"}})],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',_vm._b({attrs:{"label":"显示范围"}},'a-form-item',{
            labelCol: {
              sm: { span: 12 }
            },
            wrapperCol: {
              sm: { span: 12 }
            }
          },false),[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['minZoom',
                                                  {rules:[
                                                    {required:true,message:'请输入最小显示级别'}
                                                    ],trigger:'change',initialValue:11}
                                                  ]),expression:"['minZoom',\n                                                  {rules:[\n                                                    {required:true,message:'请输入最小显示级别'}\n                                                    ],trigger:'change',initialValue:11}\n                                                  ]"}],attrs:{"min":3,"max":18,"step":1,"placeholder":"请输入最小显示级别"}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',_vm._b({},'a-form-item',{
                        labelCol: {
                          sm: { span: 9 }
                        },
                        wrapperCol: {
                          sm: { span: 14 }
                        }
                      },false),[_vm._v(" -  "),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['maxZoom',
                                              {rules:[
                                                {required:true,message:'请输入最大显示级别'}
                                                ],trigger:'change',initialValue:18}
                                              ]),expression:"['maxZoom',\n                                              {rules:[\n                                                {required:true,message:'请输入最大显示级别'}\n                                                ],trigger:'change',initialValue:18}\n                                              ]"}],attrs:{"min":3,"max":18,"step":1,"placeholder":"请输入最大显示级别"}})],1)],1)],1),_c('a-row',[_c('a-form-item',_vm._b({attrs:{"label":"位置经纬度"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['position',
                          {rules:[
                            {type:'string',required:true,message:'请点击选择位置经纬度'}
                            ],trigger:'change'}
                          ]),expression:"['position',\n                          {rules:[\n                            {type:'string',required:true,message:'请点击选择位置经纬度'}\n                            ],trigger:'change'}\n                          ]"}],attrs:{"placeholder":"请点击选择位置经纬度"}})],1)],1)],1)],1),_c('a-row',{staticClass:"m-b-12 m-r-24 m-t-12"},[(_vm.type === 'add')?_c('a-button',{staticClass:"f-r",attrs:{"type":"primary","loading":_vm.ifSub},on:{"click":_vm.submitAdd}},[_vm._v("添加")]):_vm._e(),(_vm.type === 'edit')?_c('a-button',{staticClass:"f-r m-l-12",attrs:{"type":"primary","loading":_vm.ifSub},on:{"click":_vm.submitEdit}},[_vm._v("更新")]):_vm._e(),(_vm.type === 'edit')?_c('a-button',{staticClass:"f-r",attrs:{"type":"danger","loading":_vm.ifSub},on:{"click":_vm.submitDelete}},[_vm._v("删除")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }